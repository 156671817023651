import axios from "axios";

export interface AuthUser {
  id: string;
  username: string;
  displayName: string;
  avatar: string;
  permissions: {
    [key: string]: boolean
  }
}

export type AuthProviderType = {
  isAuthenticated: boolean;
  hasCheckedAuthenticated: boolean;
  user: AuthUser | null,
  fetchProfile: () => Promise<void>;
  logout: () => Promise<void>;
};

const api = axios.create({
  baseURL: '/api',
  timeout: 30_000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const authProvider: AuthProviderType = {
  hasCheckedAuthenticated: false,
  isAuthenticated: false,
  user: null,
  async fetchProfile() {
    if (authProvider.hasCheckedAuthenticated) {
      return;
    }

    const response = await api.get('/me');

    authProvider.hasCheckedAuthenticated = true;

    if (response.data && response.data.user) {
      authProvider.isAuthenticated = true;
      authProvider.user = response.data.user;
    }
  },
  async logout() {
    await new Promise((cb) => setTimeout(cb, 500)); // fake delay
    authProvider.isAuthenticated = false;
    authProvider.hasCheckedAuthenticated = false;
  },
};

export default authProvider;