import { Snowflake } from "discord-api-types/globals";
import HeaderBox from "../../../../../components/ui/containers/header.box";
import { useState } from "react";
import { TextOnlyChannelSelectOption } from "../../../../../providers/guild.provider";
import automodProvider from "../../../../../providers/settings.automod.provider";
import notificationProvider, { NotificationType } from "../../../../../providers/notification.provider";
import ReactSelect from "../../../../../components/select/react-select.component";

interface DiscordInvitesFormProps {
  allowDiscordInvites: Snowflake[]
  textChannels: TextOnlyChannelSelectOption[]
}

const DiscordInvitesForm: React.FC<DiscordInvitesFormProps> = ({
  allowDiscordInvites,
  textChannels
}) => {
  const [ discordInviteChannels, setDiscordInviteChannels ] = useState(allowDiscordInvites);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    automodProvider.updateSettings({ allowDiscordInvites: discordInviteChannels }!)
      .then(() => {
        notificationProvider.addNotification({
          title: "Settings Saved",
          body: "Successfully updated discord invite settings.",
          type: NotificationType.SUCCESS
        });
      })
      .catch(() => {
        notificationProvider.addNotification({
          title: "An Error Occurred",
          body: "A problem occurred whilst attempting to save, please inform @tisryno on Discord",
          type: NotificationType.DANGER
        });
      });
  };

  return (
    <HeaderBox header="Message Content Moderation">
      <form action="#" onSubmit={handleSubmit}>
        <div className="p-6.5">
          <div className="mb-4.5">
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Discord Invites (Allowed Channels)
              <br />
              <small>Any discord invites sent will be deleted and the user is Timed out for 1 hour. This allows invites in specific channels.</small>
            </label>
            <ReactSelect
              name="allowDiscordInvites"
              isMulti={true}
              required={false}
              isClearable
              options={textChannels}
              placeholder="Select a channel..."
              value={discordInviteChannels.flatMap(channelId => {
                let channels: { label: string, value: string }[] = [];
                textChannels?.forEach(category => {
                  channels.push(...(category.options?.filter(channel => channel.value === channelId) ?? []))
                })
            
                return channels
              })}
              onChange={(e) => setDiscordInviteChannels((e as { label: string; value: string; }[]).map(cb => cb.value))}
              closeMenuOnSelect
            />
          </div>

          <button type="submit" className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
            Save Settings
          </button>
        </div>
      </form>
    </HeaderBox>
  );
}

export default DiscordInvitesForm