import React from "react";

interface HeaderBoxProps {
  header: string,
  children: React.ReactElement | React.ReactElement[]
}

const HeaderBox: React.FC<HeaderBoxProps> = ({
  header,
  children,
}) => {
  return (
    <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
        <div className="flex items-start justify-between">
          <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
            {header}
          </h2>
        </div>
      </div>
      {children}
    </div>
  )
};

export default HeaderBox;