import { useEffect, useState } from "react";
import automodProvider, { IAutoModSettings } from "../../../../providers/settings.automod.provider";
import guildProvider, { TextOnlyChannelSelectOption } from "../../../../providers/guild.provider";
import Loader from "../../../../components/loader";
import DiscordInvitesForm from "./components/discord.invites.form";
import SpamForm from "./components/spam.form";

const AutoModPage = () => {
  const [ settings, setSettingsData ] = useState<IAutoModSettings | null>(null);
  const [ textChannels, setTextChannels ] = useState<TextOnlyChannelSelectOption[] | null>(null);
  
  useEffect(() => {
    automodProvider.fetchSettings()
      .then((data) => {
        setSettingsData(data.settings);
      });
    guildProvider.fetchTextOnlyChannelsAsSelectOptions()
      .then((data) => {
        setTextChannels(data);
      });
  }, []);

  if (settings === null || textChannels === null) {
    return <Loader />;
  }

  return (
    <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
      <div className="col-span-6">
        <DiscordInvitesForm allowDiscordInvites={settings?.allowDiscordInvites ?? []} textChannels={textChannels} />
      </div>

      <div className="col-span-6">
        <SpamForm mentions={settings.spam?.mentions} content={settings.spam?.content} scam={settings.spam?.scam} />
      </div>
    </div>
  );
};

export default AutoModPage;