import React, { useEffect, useState } from "react";
import guildProvider, { GuildResponse } from "../../../providers/guild.provider";
import NameValueListItem from "../../../components/data/keu-value.list";
import CardDataStats from "../../../components/ui/containers/card.data.stats";
import { UsersIcon, AtSymbolIcon, HashtagIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { ChannelType } from "discord-api-types/v10";

const Main = () => {
  const [ guild, setGuildData ] = useState<GuildResponse | null>(null);
  const [ textChannels, setTextChannels ] = useState<number>(0);
  const [ voiceChannels, setVoiceChannels ] = useState<number>(0);

  useEffect(() => {
    guildProvider.fetchGuildStatistics()
      .then((data) => {
        setGuildData(data);

        let textChannels = 0,
            voiceChannels = 0

        data.guild.channels.forEach(category => {
            if (category.channels) {
                textChannels += category.channels.filter(c => c.type === ChannelType.GuildText).length
                voiceChannels += category.channels.filter(c => c.type === ChannelType.GuildVoice).length
            } else {
                if (category.type === ChannelType.GuildText) {
                    textChannels++
                }
                if (category.type === ChannelType.GuildVoice) {
                    voiceChannels++
                }
            }
        });

        setTextChannels(textChannels);
        setVoiceChannels(voiceChannels);
      });
  }, []);

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats title="Total members" total={`${guild?.guild.members ?? 'Loading...'}`} color="primary">
          <UsersIcon className="w-10" />
        </CardDataStats>

        <CardDataStats title="Total roles" total={`${guild?.guild.roles.length ?? 'Loading...'}`} color="accent">
          <AtSymbolIcon className="w-10" />
        </CardDataStats>

        <CardDataStats title="Total text channels" total={textChannels.toString()} color="danger">
          <HashtagIcon className="w-10" />
        </CardDataStats>

        <CardDataStats title="Total voice channels" total={voiceChannels.toString()} color="warning">
          <MicrophoneIcon className="w-10" />
        </CardDataStats>
      </div>
                
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        
        <div className="col-span-6">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Bot
                </h2>
              </div>
            </div>
            {guild?.bot.avatar && <img src={guild.bot.avatar} className="absolute left-full -translate-y-[125%] -translate-x-3/4 rounded-full w-20 border-stroke dark:border-strokedark border-2" alt="Bot Avatar" />}
            <div className="px-4 py-4 md:px-6 md:py-6 xl:px-7.5">
              <div className="flex flex-col gap-6">
                {guild?.bot && <div>
                  <ul className="divide-y">
                    <NameValueListItem label="Display Name" value={guild.bot.name} />
                    <NameValueListItem label="Presence" value={guild.bot.presence.status ?? 'None'} />
                    <NameValueListItem
                      label="Permissions"
                      value={guild.bot.permissions.join(", ")}
                    />
                  </ul>
                </div>}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Main;