import { useEffect, useState } from "react";
import loggingProvider, { SettingsLogging } from "../../../providers/settings.logging.provider";
import ReactSelect from "../../../components/select/react-select.component";
import guildProvider, { GuildResponse } from "../../../providers/guild.provider";
import notificationProvider, { NotificationType } from "../../../providers/notification.provider";
import { ActionMeta, OnChangeValue } from "react-select";
import Loader from "../../../components/loader";

const LoggingPage = () => {
  const [ settings, setSettingsData ] = useState<SettingsLogging | null>(null);
  const [ guild, setGuildData ] = useState<GuildResponse | null>(null);
  
  useEffect(() => {
    loggingProvider.fetchLoggingSettings()
      .then((data) => {
        setSettingsData(data);
      });
    guildProvider.fetchGuildStatistics()
      .then((data) => {
        setGuildData(data);
      });
  }, []);

  if (settings === null || guild === null) {
    return <Loader />;
  }

  const textChannels = guild?.guild.channels.map(c => {
    return {
      label: c.name,
      options: c.channels?.filter(c =>
        c.type === 0 &&
        (c.permissions.includes('SendMessages') && c.permissions.includes('ViewChannel'))
      )?.map(channel => ({label: channel.name, value: channel.id})),
    }
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);

    const object: Record<string, any> = {};
    formData.forEach((value, key) => object[key] = value);

    loggingProvider.updateLoggingSettings(object as SettingsLogging)
      .then(() => {
        setSettingsData(object);
        notificationProvider.addNotification({
          title: "Settings Saved",
          body: "Successfully updated the log settings.",
          type: NotificationType.SUCCESS
        });
      });
  };

  const onUpdate = (event: OnChangeValue<any, false>, action: ActionMeta<unknown>) => {
    setSettingsData({
      ...settings,
      [action.name as string]: event === null ? null : event.value
    })
  };

  let chosenMessageDeleteChannel,
      chosenMessageEditChannel,
      chosenMemberJoinedChannel,
      chosenMemberLeaveChannel,
      chosenModActionChannel

  guild?.guild.channels.forEach(c => {
    c.channels?.forEach(c1 => {
      if (c1.id === settings?.messageDelete) {
        chosenMessageDeleteChannel = {
          label: c1.name,
          value: c1.id
        }
      }
      if (c1.id === settings?.messageEdit) {
        chosenMessageEditChannel = {
          label: c1.name,
          value: c1.id
        }
      }
      if (c1.id === settings?.memberJoined) {
        chosenMemberJoinedChannel = {
          label: c1.name,
          value: c1.id
        }
      }
      if (c1.id === settings?.memberLeave) {
        chosenMemberLeaveChannel = {
          label: c1.name,
          value: c1.id
        }
      }
      if (c1.id === settings?.modActionLog) {
        chosenModActionChannel = {
            label: c1.name,
            value: c1.id
        }
      }
    })
  })

  return (
    <div>
      <div className="mt-7.5 grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">
        <div className="col-span-6">

          <div className="rounded-sm relative border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke px-4 py-4 dark:border-strokedark md:px-6 md:py-6 xl:px-7.5">
              <div className="flex items-start justify-between">
                <h2 className="text-title-sm2 self-center font-bold text-black dark:text-white">
                  Bot Logging Channels
                </h2>
              </div>
            </div>
            <form action="#" onSubmit={handleSubmit}>
              <div className="p-6.5">
                <div className="mb-4.5">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Message Delete
                  </label>
                  <ReactSelect
                    name="messageDelete"
                    isMulti={false}
                    required={false}
                    isClearable
                    options={textChannels}
                    placeholder="Select a channel..."
                    value={chosenMessageDeleteChannel}
                    onChange={onUpdate}
                    closeMenuOnSelect
                  />
                </div>

                <div className="mb-4.5">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Message Update
                  </label>
                  <ReactSelect
                    name="messageEdit"
                    isMulti={false}
                    required={false}
                    isClearable
                    options={textChannels}
                    placeholder="Select a channel..."
                    value={chosenMessageEditChannel}
                    onChange={onUpdate}
                    closeMenuOnSelect
                  />
                </div>

                <div className="mb-4.5">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    User Joined
                  </label>
                  <ReactSelect
                    name="memberJoined"
                    isMulti={false}
                    required={false}
                    isClearable
                    options={textChannels}
                    placeholder="Select a channel..."
                    value={chosenMemberJoinedChannel}
                    onChange={onUpdate}
                    closeMenuOnSelect
                  />
                </div>

                <div className="mb-4.5">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    User Left
                  </label>
                  <ReactSelect
                    name="memberLeave"
                    isMulti={false}
                    required={false}
                    isClearable
                    options={textChannels}
                    placeholder="Select a channel..."
                    value={chosenMemberLeaveChannel}
                    onChange={onUpdate}
                    closeMenuOnSelect
                  />
                </div>

                <div className="mb-4.5">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Moderator Actions
                  </label>
                  <ReactSelect
                    name="modActionLog"
                    isMulti={false}
                    required={false}
                    isClearable
                    options={textChannels}
                    placeholder="Select a channel..."
                    value={chosenModActionChannel}
                    onChange={onUpdate}
                    closeMenuOnSelect
                  />
                </div>

                <button type="submit" disabled={settings === null} className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray enabled:hover:bg-opacity-90 disabled:opacity-50">
                  Save Settings
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default LoggingPage;