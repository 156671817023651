import {
  ScaleIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
  ChatBubbleLeftEllipsisIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export type NavItem = {
  label: string;
  path: string;
  icon: React.ReactNode;
  children?: NavItem[];
};

const navItems: NavItem[] = [
  {
    path: "/",
    label: "Dashboard",
    icon: <HomeIcon className="w-6 h-6" />,
  },
  {
    label: "Settings",
    path: "/settings",
    icon: <WrenchScrewdriverIcon className="w-6 h-6" />,
    children: [
      {
        label: "Auto-Mod",
        path: "/settings/auto-mod",
        icon: <ScaleIcon className="w-6 h-6" />
      },
      {
        label: "Logging",
        path: "/settings/logging",
        icon: <ChatBubbleLeftEllipsisIcon className="w-6 h-6" />
      },
      {
        label: "Permissions",
        path: "/settings/permissions",
        icon: <UsersIcon className="w-6 h-6" />
      },
    ]
  },
];

export async function loadNavItems() {
  return navItems;
}